import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import NavBar from '../components/index/navbar';
import Footer from '../components/footer';
import '../styles/main.scss';

const thanks = () => {
    return(
        <Layout>
            <NavBar/>
            <div className='background-thanks'> {/*Background container*/}
                <StaticImage
                className='background-thanks__image'
                placeholder='none'
                src='../images/fondo_gracias.png'
                alt='thanks-img-laboratorioclinico'
                />
                {/*Main content container*/}
                <div className='thanks'>
                    
                     {/*button container*/}
                    <div className='thanks__button'>
                        <i className='fas fa-circle fa-xs'></i>
                        <p>Laboratorio Clínico G&S</p>
                    </div>

                    {/*Message container*/}
                    <div className='thanks__message'>
                        <p className='thanks__message-1'>¡Gracias!</p>
                        <p className='thanks__message-2'>Por confiar en el equipo de<br></br>Laboratorio Clínico G&S y por preferir<br></br>nuestros servicios</p>
                        <button className='thanks__message-button' style={{color: 'white', }}>
                            <i className="fa-solid fa-angle-left thanks__message-button-icon"></i>         {/*Button icon class comes from Font Awesome*/}
                            <Link to='/' className='thanks__message-button-go-back'>Regresar</Link>
                        </button>
                    </div>
                </div>
            </div>
            <Footer/>
        </Layout>
    );
}

export default thanks;